import { HadoukenIcon, EvmDebuggerIcon } from '../Icons';
import { DevilWalletIcon } from '../Icons/DevilWallet/DevilWalletIcon/DevilWalletIcon';
import type { SubmenuBlockProps } from '../SubmenuBlock/SubmenuBlock.types';

const blocks = [
  {
    name: 'EVM Debugger',
    caption:
      'EVM Debugger is a unique, free tool for EVM & Defi developers. It enables really easy and user-friendly analysis of Ethereum smart contracts.',
    icon: EvmDebuggerIcon,
    linkTo: '/evm-debugger',
  },
  {
    name: 'Devil Wallet',
    caption:
      'Devil Wallet is a unique mobile wallet redefining the way users interact with Web3 and smart contracts. We use Account Abstraction to enable user-friendly onboarding, easy recovery, enhanced security and full compatibility with WalletConnect.',
    icon: DevilWalletIcon,
    linkTo: '/devil-wallet',
  },
];

const shouldShowWallet = process.env.NEXT_PUBLIC_SHOW_DEVIL_WALLET === 'true';
const productsToShow = [
  'EVM Debugger',
  'Hadouken',
  shouldShowWallet && 'Devil Wallet',
];
export const productsSubmenuData: {
  blocks: SubmenuBlockProps[];
} = {
  blocks: blocks.filter((block) => productsToShow.includes(block.name)),
};
