import { Hidden, useTheme, Stack } from '@mui/material';
import React from 'react';
import evmDebuggerScreen from '../../../assets/png/AlgeaTheme/evmDebuggerScreen.png';
import hadoukenScreen from '../../../assets/png/AlgeaTheme/hadoukenScreen.png';
import { Section } from '../index';
import type { ProductsSubmenuProps } from './ProductsSubmenu.types';
import {
  StyledDivider,
  StyledStack,
  StyledBlogSectionWrapper,
  StyledSectionWrapper,
  StyledMenuBoxImage,
  StyledText,
} from './styles';

export const ProductsSubmenu = ({ ...props }: ProductsSubmenuProps) => {
  const productsData = [
    {
      text: 'EVM Debugger is a unique, free tool for EVM & Defi developers. It enables really easy and user-friendly analysis of Ethereum smart contracts.',
      headline: 'EVM Debugger',
      img: evmDebuggerScreen,
      link: `/evm-debugger`,
    },
  ];

  const theme = useTheme();
  return (
    <Section
      backgroundColor="unset"
      width={theme.utils.isMobile() ? 'full' : 'small'}>
      <StyledStack
        gap={2}
        direction="column"
        alignItems="flex-start"
        justifyContent="space-between"
        {...props}>
        <StyledSectionWrapper sx={{ width: 'auto' }}>
          <Stack
            sx={{
              margin: theme.spacing(0, 0, 2, 0),
              [theme.breakpoints.down('md')]: {
                margin: theme.spacing(0),
              },
            }}>
            <StyledText variant="buttonSmall">Visit our products</StyledText>
          </Stack>

          <StyledDivider sx={{ display: { md: 'none' } }} />

          <StyledBlogSectionWrapper>
            {productsData?.map((item, index) => (
              <StyledMenuBoxImage
                key={index}
                {...item}
                sx={{ img: { filter: 'none !important' } }}
              />
            ))}
          </StyledBlogSectionWrapper>
        </StyledSectionWrapper>
      </StyledStack>
    </Section>
  );
};
